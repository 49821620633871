<template>
  <div>
    <div class="row">
      <div class="col-md-5">
        <select name="selectFamilia" class="form-control" v-model="id_familia">
          <option :value="null">Cualquier familia</option>
          <option :value="familia.id" v-for="familia in familias" :key="`familia-${familia.id}`">
            {{familia.division}} {{familia.familia != null? `| Familia ${familia.familia}` : ''}}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <input name="dato" type="text" class="form-control" placeholder="Dato del artículo"
          v-model="dato"
          v-on:keyup.enter="refrescar()">
      </div>
      <div class="col-md-3">
        <button class="btn btn-primary" @click="refrescar()">
          <i class="fa fa-filter"></i>
          Filtrar
        </button>
        <button class="btn btn-default" @click="cancelar()">
          <i class="fa fa-close"></i>
          Cancelar
        </button>
      </div>
    </div>
    <hr>
    <div >
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre del artículo</th>
            <th style="width: 55px">Familia</th>
            <th>División</th>
            <th style="width: 100px">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(articulo, index) in articulos" :key="`art-${index}`">
            <td>{{articulo.id}}</td>
            <td>{{articulo.nombre}}</td>
            <td class="text-right">{{articulo.familia.familia}}</td>
            <td>{{articulo.familia.division}}</td>
            <td>
              <button class="btn btn-default btn-xs" @click="seleccionar(articulo)">
                Seleccionar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'

export default {
  data() {
    return {
      dato: '',
      id_familia: null,
      familias: [],
      articulos: []
    }
  },
  created() {
    this.cargarFamilias()
  },

  methods: {
    cancelar() {
      this.$emit('seleccion:cancelada')
    },

    cargarFamilias() {
      let self = this
      let params = {
        sin_paginacion: true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        self.familias = response.data
      }).catch(error => {
        iu.msg.error('No se pudieron cargar las familias')
      })
    },

    limpiar() {
      this.dato = ''
      this.id_familia = null,
      this.articulos = []
    },

    refrescar() {
      let self = this
      let params = {
        dato: self.dato,
        id_familia: self.id_familia,
        con_familia: true,
        sin_paginacion: true
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        self.articulos = response.data
      }).catch(error => {
        iu.msg.error('No se pudieron refrescar los artículos')
      })
    },

    seleccionar(articuloSeleccionado) {
      let articulo = Object.assign({}, articuloSeleccionado)
      this.limpiar()
      this.$emit('articulo-seleccionado', articulo)
    }
  }
}
</script>
