<template>
  <div>
    <div class="btn-group">
      <!-- Para tablero -->
      <span class="btn btn-primary"
        v-show="seccion == 'tablero' && $store.getters.tienePermiso('tableroInventario', 'vista')"
      >
        Tablero
      </span>
      <router-link
        :to="{ name: 'tableroInventario' }"
        v-show="seccion != 'tablero' && $store.getters.tienePermiso('tableroInventario', 'vista')"
        class="btn btn-white"
      >
        Tablero
      </router-link>
      <!-- Fin para tablero -->

      <!-- Para familia articulos -->
      <span class="btn btn-primary" 
        v-show="seccion == 'familias' && $store.getters.tienePermiso('listadoFamiliaArticulos', 'vista')"
      >
        Familia de articulos
      </span>
      <router-link
        :to="{ name: 'listadoFamiliaArticulos' }"
        v-show="seccion != 'familias' && $store.getters.tienePermiso('listadoFamiliaArticulos', 'vista')"
        class="btn btn-white"
      >
        Familia de articulos
      </router-link>
      <!-- Fin para familia articulos -->

      <!-- Para articulos -->
      <span class="btn btn-primary"
        v-show="seccion == 'articulos' && $store.getters.tienePermiso('listadoArticulos', 'vista')"
      >
        Articulos
      </span>
      <router-link
        :to="{ name: 'listadoArticulos' }"
        v-show="seccion != 'articulos' && $store.getters.tienePermiso('listadoArticulos', 'vista')"
        class="btn btn-white"
      >
        Articulos
      </router-link>
      <!-- Fin para articulos -->


      <!-- Para solicitudes -->
      <span class="btn btn-primary" 
        v-show="seccion == 'solicitudes' && $store.getters.tienePermiso('listadoSolicitudes', 'vista')"
      >
        Solicitudes
      </span>
      <router-link
        :to="{ name: 'listadoSolicitudes' }"
        v-show="seccion != 'solicitudes' && $store.getters.tienePermiso('listadoSolicitudes', 'vista')"
        class="btn btn-white"
      >
        Solicitudes
      </router-link>
      <!-- Fin para solicitudes -->
      

      <!-- Para entradas de articulos -->
      <span class="btn btn-primary" v-show="seccion == 'entradas' && $store.getters.tienePermiso('listadoEntradasAlmacen', 'vista')">
        Entradas
      </span>
      <router-link
        :to="{ name: 'listadoEntradasAlmacen' }"
        v-show="seccion != 'entradas' && $store.getters.tienePermiso('listadoEntradasAlmacen', 'vista')"
        class="btn btn-white"
      > 
        Entradas
      </router-link>
      <!-- Fin para entradas de articulos -->

      

      <!-- Para salidas de articulos -->
      <span class="btn btn-primary" v-show="seccion == 'salidas' && $store.getters.tienePermiso('listadoSalidasAlmacen', 'vista')">
        Salidas
      </span>
      <router-link
        :to="{ name: 'listadoSalidasAlmacen' }"
        v-show="seccion != 'salidas' && $store.getters.tienePermiso('listadoSalidasAlmacen', 'vista')"
        class="btn btn-white"
      > 
        Salidas
      </router-link>
      <!-- Fin para salidas de articulos -->
      

      <!-- Para conversiones de articulos -->
      <!--span class="btn btn-primary" v-show="seccion == 'conversiones'">
        Conversiones
      </span>
      <router-link
        :to="{ name: 'listadoConversionesArticulos' }"
        v-show="seccion != 'conversiones'"
        class="btn btn-white"
      > 
        Conversiones
      </router-link-->
      <!-- Fin para conversiones de articulos -->



      <!-- Para movimientos de articulos -->
      <span class="btn btn-primary"
        v-show="seccion == 'movimientos' && $store.getters.tienePermiso('listadoMovimientosArticulos', 'vista')"
      >
        Movimientos por artículo
      </span>
      <router-link
        :to="{ name: 'listadoMovimientosArticulos' }"
        v-show="seccion != 'movimientos' && $store.getters.tienePermiso('listadoMovimientosArticulos', 'vista')"
        class="btn btn-white"
      >
        Movimientos por artículos
      </router-link>
      <!-- Fin para movimientos articulos -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['seccion']
}
</script>
