<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-12">
        <h2>Inventario</h2>
        <ol class="breadcrumb">
          <li>
            <router-link :to="{ name: 'listadoArticulos' }">
              Artículos
            </router-link>
          </li>
          <li>
            <strong>Conversiones</strong>
          </li>
        </ol>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-lg-12">
          <cmp-secciones seccion="conversiones"></cmp-secciones>
        </div>
      </div>
      <br><br>
      
      <div class="ibox">
        <div class="ibox-title">
          <h5>{{modo=='nueva'?'Nueva conversión': 'Visor de conversión'}} de artículos</h5>
          <div class="ibox-tools">
            <a @click="cerrar()">
              <i class="fa fa-close"></i>
            </a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row" v-show="modo=='visor'">
            <div class="col-md-3">
              <label for="idConversion">ID de conversión del almacén</label>
              <input name="idConversion" type="text" 
                class="form-control text-right" v-model="conversion.id_convalm"
                readonly>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="notaSalida">Nota acerca de la conversión</label>
              <textarea name="notaSalida" v-model="conversion.nota" class="form-control"
                :readonly="modo=='visor'"></textarea>
            </div>
          </div>
          
          <br>
          
          <div class="text-right">
            <button @click="mostrarMdlNuevaPartida()" class="btn btn-default btn-sm"
              v-show="modo=='nueva'">
              <i class="fa fa-plus"></i>
              Nueva partida
            </button>
          </div>
          <div class="row m-t-xs">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover" style="min-width: 1200px">
                  <thead>
                    <tr>
                      <th class="bg-muted"></th>
                      <th colspan="3" class="bg-warning">Artículo a convertir</th>
                      <th colspan="3" class="bg-info">Artículo convertido</th>
                      <th class="bg-muted"></th>
                    </tr>
                    <tr>
                      <th style="width: 50px">#</th>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Unidad de medida</th>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Unidad de medida</th>
                      <th class="text-center"
                        v-show="modo == 'nueva'">
                        Acción
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(partida,index) in conversion.partidas" :key="`partida-${index+1}`">
                      <td>{{index+1}}</td>
                      <td>{{partida.nombre_articulo_inicial}}</td>
                      <td class="text-right">{{partida.cantidad_inicial}}</td>
                      <td>{{partida.abreviatura_unidad_medida_inicial}}</td>
                      <td>{{partida.nombre_articulo_final}}</td>
                      <td>{{partida.cantidad_final}}</td>
                      <td>{{partida.unidad_medida_final}}</td>
                      <td class="text-center" v-show="modo == 'nueva'">
                        <button class="btn btn-danger btn-xs" @click="eliminarPartida(index)">
                          <i class="fa fa-close"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <button @click="cerrar()" class="btn btn-default">
          <i class="fa fa-angle-left"></i>
          Atrás
        </button>
        <button class="btn btn-primary" @click="guardar()"
          title="Saca de inventario las cantidades de los artículos indicados"
          v-show="modo == 'nueva'">
          <i class="fa fa-gears"></i>
          Realizar conversión
        </button>
      </div>
        
    </div>

    <MdlNuevaPartida ref="mdlNuevaPartidaSalida"
      v-on:partida:lista="agregarPartida($event)"/>
  </div>
</template>

<script>
import PeriodoInventarioSrv from '@/services/inventario/PeriodoInventarioSrv.js'
import ConversionSrv from '@/services/inventario/ConversionSrv.js'
import CmpSecciones from '../CmpSecciones.vue'
import MdlNuevaPartida from './MdlNuevaPartida.vue'

export default {
  components: {
    CmpSecciones, MdlNuevaPartida
  },
  data: function () {
    return {
      modo: 'nueva',
      conversion: {
        id: null,
        id_convalm: null,
        nota: '',

        /**
         * Cada partida tiene la siguiente estructura
         * 
         * partidas: [
         *    {
         *      id_articulo_inicial: int,
         *      id_unidad_medida_inicial: int,
         *      cantidad_inicial: float,
         *      id_articulo_final: int,
         *      id_unidad_medida_final: int,
         *      cantidad_final: float
         *    },
         *    ...
         * ]
         */
        partidas: []
      }
    }
  },
  created() {
    var self = this
    
    if(self.$route.params.id != undefined) {
      self.conversion.id = self.$route.params.id
      self.modo = 'visor'
      
      self.cargarConversion()
    } 
  },
  mounted() {
    let self = this
    setTimeout(function(){
      self.mostrarMdlNuevaPartida()
    }, 500)
    
  },
  methods: {
    atras() {
      this.$router.go(-1)
    },

    agregarPartida(nuevaPartida) {
      var self = this

      self.conversion.partidas.push(nuevaPartida)
    },

    cargarConversion() {
      let self = this
      let idConversion = this.$route.params.id

      ConversionSrv.conversionJSON(idConversion).then(response => {
        let conversion = response.data
        self.conversion = conversion
      })
    },

    cerrar() {
      this.atras()
    },

    eliminarPartida(index) {
      this.conversion.partidas.splice(index, 1)
    },

    mostrarMdlNuevaPartida() {
      this.$refs.mdlNuevaPartidaSalida.mostrar()
    },

    guardar(){
      var self = this

      if(self.conversion.partidas.length == 0) {
        iu.msg.warning('No se puede realizar la conversion de artículos, ya que no se ha seleccionado ningún artículo')
        return
      }

      let conversion = Object.assign({}, self.conversion)
      conversion.partidas = conversion.partidas.map(partida => {
        delete partida.abreviatura_unidad_medida
        delete partida.descripcion_articulo
        delete partida.division
        delete partida.familia
        delete partida.nombre_articulo
        return partida
      })

      ConversionSrv.guardar(conversion).then(response => {
        iu.msg.success('Se guardó correctamente la conversion')
        self.$router.go(-1)
      }).catch(error => {
        let mensaje 
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo realizar la conversion'
        }
        iu.msg.warning(mensaje)
        console.log(error)
      })
    },
  }
}
</script>

