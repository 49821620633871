<template>
  <div class="modal fade" tabindex="-1" role="dialog" ref="mdlNuevaPartidaEntrada">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Nueva partida de conversión de artículo</h4>
        </div>
        <div class="modal-body">
          <SeleccionadorDeArticulos v-show="mostrarDivSeleccionArticulo"
            v-on:articulo-seleccionado="agregarArticuloSeleccionado($event)"
            v-on:seleccion:cancelada="ocultarSeleccionadorArticulo()"/>
          
          <div v-show="!mostrarDivSeleccionArticulo">
            <h3>Artículo a convertir</h3>
            <div class="row">
              <div class="col-md-6">
                <label>Nombre del artículo</label><br>
                <button class="btn btn-default" v-show="nuevaPartida.id_articulo_inicial == null"
                  @click="articuloASeleccionar=1; mostrarDivSeleccionArticulo=true">
                  Seleccionar
                </button>

                <div class="input-group" v-show="nuevaPartida.id_articulo_inicial != null">
                  <input type="text" v-model="nuevaPartida.nombre_articulo_inicial" readonly
                    class="form-control">
                  <span class="input-group-btn">
                    <button class="btn btn-default" title="Cambiar artículo a convertir"
                      @click="articuloASeleccionar=1; mostrarDivSeleccionArticulo=true">
                      C
                    </button>
                  </span>
                </div>
              </div>
              <div class="col-md-3">
                <label>Cantidad</label>
                <input
                  type="number"
                  v-model="nuevaPartida.cantidad_inicial"
                  class="form-control text-right"
                  name="cantidad"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  :disabled="nuevaPartida.id_articulo_inicial == null"
                />
              </div>
              <div class="col-md-3">
                <label>Unidad de medida</label>
                <select class="form-control" v-model="nuevaPartida.id_unidad_medida_inicial"
                  @change="cargarAbreviaturaUnidadMedidaANuevaPartida()"
                  :disabled="nuevaPartida.id_articulo_inicial == null || unidadesMedidaArticuloInicial.length == 0">
                  <option :value="null">{{unidadesMedidaArticuloInicial.length == 0? 'No disponibles': 'Seleccionar'}}</option>
                  <option v-for="um in unidadesMedidaArticuloInicial" :value="um.id" :key="`opt-${um.id}`">
                    {{um.abreviatura}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <br v-show="!mostrarDivSeleccionArticulo">
          <hr v-show="!mostrarDivSeleccionArticulo">

          <div v-show="!mostrarDivSeleccionArticulo">
            <h3>Artículo convertido</h3>
            <div class="row">
              <div class="col-md-6">
                <label>Nombre del artículo</label><br>
                <button class="btn btn-default" v-show="nuevaPartida.id_articulo_final == null"
                  @click="articuloASeleccionar=2; mostrarDivSeleccionArticulo=true">
                  Seleccionar
                </button>

                <div class="input-group" v-show="nuevaPartida.id_articulo_final != null">
                  <input type="text" v-model="nuevaPartida.nombre_articulo_final" readonly
                    class="form-control">
                  <span class="input-group-btn">
                    <button class="btn btn-default" title="Cambiar artículo convertido"
                      @click="articuloASeleccionar=2; mostrarDivSeleccionArticulo=true">
                      C
                    </button>
                  </span>
                </div>
              </div>
              <div class="col-md-3">
                <label>Cantidad</label>
                <input
                  type="number"
                  v-model="nuevaPartida.cantidad_final"
                  class="form-control text-right"
                  name="cantidad"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  :disabled="nuevaPartida.id_articulo_final == null"
                />
              </div>
              <div class="col-md-3">
                <label>Unidad de medida</label>
                <select class="form-control" v-model="nuevaPartida.id_unidad_medida"
                  @change="cargarAbreviaturaUnidadMedidaANuevaPartida()"
                  :disabled="nuevaPartida.id_articulo_final == null || unidadesMedidaArticuloFinal.length == 0">
                  <option :value="null">{{unidadesMedidaArticuloFinal.length == 0? 'No disponibles': 'Seleccionar'}}</option>
                  <option v-for="um in unidadesMedidaArticuloFinal" :value="um.id" :key="`opt-${um.id}`">
                    {{um.abreviatura}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary"
            @click="agregarPartida()">
            <i class="fa fa-plus"></i>
            Agregar
          </button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>


<script>
import FamiliaArticulosSrv  from '@/services/inventario/FamiliaArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import SeleccionadorDeArticulos from './SeleccionadorDeArticulos.vue'

export default {
  components: { SeleccionadorDeArticulos },
  data() {
    return {
      mostrarDivSeleccionArticulo: false,
      articuloASeleccionar: 1,      // 1 = Artículo a convertir, 2 = Artículo convertido esperado
      articulox: 'prueba',
      articulos: [],
      unidadesMedida: [],
      familia: {
        familia: null,
        division: '',
        descripcion: '',
      },

      familias: [],

      nuevaPartida: {
        id_articulo_inicial: null,
        nombre_articulo_inicial: '',
        id_unidad_medida_inicial: null,
        abreviatura_unidad_medida_inicial: '',
        cantidad_inicial: 0,

        id_articulo_final: null,
        nombre_articulo_final: '',
        id_unidad_medida_final: null,
        abreviatura_unidad_medida_final: '',
        cantidad_final: 0,
      },

      unidadesMedidaArticuloInicial: [],
      unidadesMedidaArticuloFinal: []
    }
  },
  created() {
    this.cargarFamilias()
    
  },
  methods: {
    agregarArticuloSeleccionado(articulo) {
      let self = this
      this.mostrarDivSeleccionArticulo = false

      switch(this.articuloASeleccionar) {
        // Artículo inicial
        case 1: 
          Object.assign(this.nuevaPartida, {
            id_articulo_inicial: articulo.id,
            nombre_articulo_inicial: articulo.nombre,
            id_unidad_medida_inicial: null,
            abreviatura_unidad_medida_inicial: '',
            cantidad_inicial: 0
          })

          ArticuloSrv.unidadesMedidaArticuloJSON(articulo.id).then(response => {
            self.unidadesMedidaArticuloInicial = response.data
          })
          break;

        // Artículo final
        case 2:
          Object.assign(this.nuevaPartida, {
            id_articulo_final: articulo.id,
            nombre_articulo_final: articulo.nombre,
            id_unidad_medida_final: null,
            abreviatura_unidad_medida_final: '',
            cantidad_final: 0
          })

          ArticuloSrv.unidadesMedidaArticuloJSON(articulo.id).then(response => {
            self.unidadesMedidaArticuloFinal = response.data
          })
          break;
      }
    },

    agregarPartida() {
      let self = this

      if (!self.verificarDatosCorrectos()) return

      var articulo = self.articulos.filter(articulo => articulo.id == self.nuevaPartida.id_articulo)

      let nuevaPartida = Object.assign({}, self.nuevaPartida)

      Object.assign(nuevaPartida, {
        nombre_articulo: articulo[0].nombre,
        descripcion_articulo: articulo[0].descripcion
      })
      
      this.$emit('partida:lista', nuevaPartida)
      
      this.limpiarNuevaPartida()
      this.limpiarUnidadesMedida()

      $(this.$refs.mdlNuevaPartidaEntrada).modal('hide')
    },


    cargarAbreviaturaUnidadMedidaANuevaPartida() {
      let unidadMedida = this.unidadesMedida.find(um => um.id == this.nuevaPartida.id_unidad_medida )
      this.nuevaPartida.abreviatura_unidad_medida = unidadMedida.abreviatura
    },

    cargarArticulos(idFamilia) {
      var self = this

      let params = {
        page: 1,
        epp: 10,
        sin_paginacion: true,
        id_familia: idFamilia,
        con_unidades_medida: true
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        let page = response.data
        self.articulos = page
        
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamilia() {
      let self = this

      let idFamilia = self.nuevaPartida.id_familia
      
      if(idFamilia == null){
          self.familia = {
            familia: null,
            division: '',
            descripcion: '',
          }
          self.articulos = []
          self.id_articulo = null
          return iu.msg.warning('No se ha seleccionado una familia debe de seleccionar una familia válida')
      } 

      this.limpiarUnidadesMedida()

      FamiliaArticulosSrv.familiaJSON(idFamilia).then(response => {
        self.familia = response.data
        self.cargarArticulos(self.familia.id)

        Object.assign(self.nuevaPartida, {
          division: self.familia.division,
          familia: self.familia.familia
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la familia de artículo '+idFamilia
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamilias() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv: true,
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        self.familias = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarUnidadesMedidaArticuloNuevaPartida() {
      let self = this
      
      // Si el id de artículo es null, se limpia la lista de unidades de medida
      if(self.nuevaPartida.id_articulo == null) {
        self.limpiarUnidadesMedida()
        return
      }
      
      // Si se selecciona un id de artículo distinto de null se solicitan sus unidades de medida
      ArticuloSrv.unidadesMedidaArticuloJSON(self.nuevaPartida.id_articulo).then(response => {
        let unidadesMedida = response.data
        self.unidadesMedida = unidadesMedida
      })
    },

    limpiarNuevaPartida() {
      this.familia = {
        familia: null,
        division: '',
        descripcion: '',
      }
      this.articulos = []

      Object.assign(this.nuevaPartida, {
        id_articulo: null,
        id_familia: null,
        cantidad: 0,
        id_unidad_medida: null
      })
    },


    limpiarUnidadesMedida() {
      this.unidadesMedida = []
    },

    mostrar() {
      this.articuloASeleccionar = 1
      $(this.$refs.mdlNuevaPartidaEntrada).modal('show')
    },


    ocultarSeleccionadorArticulo() {
      this.mostrarDivSeleccionArticulo = false
    },

    verificarDatosCorrectos(){
      var self = this
      let nvapart = self.nuevaPartida

      if(nvapart.id_familia == null) {
        iu.msg.warning('Es necesario seleccionar una división')
        $('select[name="division"]').select().focus()
        return false
      }

      if(nvapart.id_articulo == null){
        iu.msg.warning('Es necesario seleccionar un artículo')
        $('select[name="articulo"]').select().focus()
        return false
      }

      if(nvapart.cantidad == null || nvapart.cantidad < 1){
        iu.msg.warning('Se necesita una cantidad válida y que sea mínimo 1')
        $('input[name="cantidad"]').select().focus()
        return false
      }

      return true
    },
  }
}
</script>
